<template>
  <div class="pay">
    <div class="pay-info">
      <div class="info">
        <div class="title">商品订单：</div>
        <div class="label">{{ orderId }}</div>
      </div>
      <div class="info">
        <div class="title">商品名称：</div>
        <div class="label">{{ plan.stName.indexOf('下载流量') !== -1 ? 'eshop下载流量套餐' : 'eshop下载加量包'}}</div>
      </div>
      <div class="info">
        <div class="title">{{ plan.stName.indexOf('下载流量') !== -1 ? '绑定邮箱：' : '用户名：'}}</div>
        <div class="label">{{ email }}</div>
      </div>
      <div class="info">
        <div class="title">流量：</div>
        <div class="label">{{ plan.name }}</div>
      </div>
      <div class="info">
        <div class="title">支付金额：</div>
        <div class="label">
          ￥ <span style="color: #69D9BA;">{{ plan.price }}</span>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div id="qrcode"></div>
    <div class="prompt">请打开手机微信，扫一扫完成支付</div>
  </div>
</template>
<script>
/* eslint-disable */
import QRCode from "qrcodejs2"
import Toast from "@/components/Toast"
import Loading from "@/components/Loading"
import { getOrderInfo } from "@/api/order"
import { reportError } from "@/utils/log"

export default {
  name: "PayPC",
  data() {
    return {
      toastMsg: "",
      email: '',
      plan: {
        name: '',
        stName: '',
        price: '',
      },
      orderId: ""
    }
  },
  components: {
    Toast,
    Loading,
    QRCode,
  },
  methods: {
    qrcode(url) {
      let qrcode = new QRCode("qrcode", {
        text: url,
      })
    },
    async getOrderInfo() {
      let res = await getOrderInfo({
        orderId: this.orderId,
      })
      if (res.data.code == 0) {
        if (!this.plan.name) {
          this.plan.stName = res.data.order.stName
          this.plan.name = res.data.order.skuName
          this.plan.price = res.data.order.price
          this.email = res.data.order.email
        }
        if (res.data.order.status == 3) {
          this.$router.push(
            '/accelrator/order/pay-success?orderId=' + this.orderId
          )
        } else {
          let timeout = setTimeout(() => {
            this.getOrderInfo()
          }, 3000)
        }
      }
    },
  },
  mounted() {
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId
      this.qrcode(this.$route.query.qrUrl)
      this.getOrderInfo()
    }
  },
}
</script>

<style lang="less" scoped>
.pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .pay-info {
    width: 7.5rem;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: center;
    .info {
      margin-left: 0.24rem;
      display: flex;
      height: 0.6rem;
      width: 7.02rem;
      align-items: center;
      .title {
        font-size: 0.28rem;
        color: #565656;
        font-weight: bold;
      }
      .label {
        font-size: 0.28rem;
        color: #565656;
      }
    }
  }
  .divider {
    width: 100%;
    height: 0.02rem;
    background: #f6f7f8;
  }
  #qrcode {
    margin-top: 0.36rem;
    margin-bottom: 0.24rem;
  }
  .prompt {
    width: 4.5rem;
    height: 0.7rem;
    text-align: center;
    line-height: 0.7rem;
    font-size: 0.28rem;
    border-radius: 0.04rem;
    background: #69d9ba;
    color: white;
  }
}
</style>
