import { render, staticRenderFns } from "./PayPC.vue?vue&type=template&id=c6f3d2c4&scoped=true&"
import script from "./PayPC.vue?vue&type=script&lang=js&"
export * from "./PayPC.vue?vue&type=script&lang=js&"
import style0 from "./PayPC.vue?vue&type=style&index=0&id=c6f3d2c4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6f3d2c4",
  null
  
)

export default component.exports